import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Row, Col, Image } from "react-bootstrap"

//images imports
import Workshops1 from "./../images/workshops/fp-workshop.jpeg"
import Workshops2 from "./../images/workshops/workshops1.jpg"
import Workshops4 from "./../images/workshops/workshops3.jpg"
import Workshops5 from "./../images/workshops/workshops4.jpg"
import Workshops6 from "./../images/workshops/workshops5.jpg"

const Workshops = ({location, id}) => (
    <Layout>
        <Seo title="Workshops" description="Workshops, organised by Manon Lambeens"/>
        <Row className="">
            <Col xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-1">
                <h1 className="text-uppercase fs-4 text-dark">workshops</h1>
                <p className="textAbout">As part of my practice, I organize workshops. In these workshops we mainly work on typography and printing techniques. A second important part is the social character, how do we design together? The last approach of the workshops is accessibility. This low threshold is created by the use of tools. These tools can be techniques, stencils ....  
                    </p>
                   
        </Col>
      </Row>
      
      <Row className="">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={Workshops1} fluid style={{width: '100%'}} alt="Workshop Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={Workshops2} fluid style={{width: '100%'}} alt="Workshop Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={Workshops4} fluid alt="Workshop Manon Lambeens"/>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={Workshops5} fluid alt="Workshop Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={Workshops6} fluid style={{width: '100%'}} alt="Workshop Manon Lambeens"/>
        </Col>
      </Row>
    </Layout>
)

export default Workshops;